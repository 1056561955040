<template>
<div>

  <image-library-dialog
      @select="image = $event "
      v-if="displayLibrary"
      v-model="displayLibrary"
      :disable-portal="disablePortal"
  />

  <div  v-if="label" class="d-flex justify-space-between align-center pb-2">
    <h5 :style="`color : ${labelColor || '#4F5A6E' }; font-size: 13px; font-weight: 500 `" >  {{  label  }} </h5>
    <ws-tooltip :text="tooltip" v-if="tooltip">
      <v-icon :color="wsDARKLIGHT">mdi-information-outline</v-icon>
    </ws-tooltip>
  </div>

  <v-sheet v-show="!image && !buttonOnly" :height="height" class="mt-n2">
    <ws-file-uploader
        @success="uploadImage"
        @upload-start="image = null"
        @loading="uploading = $event"
        @loading-progress="updateLoadingProgress"
        @set-select-function="selectFunction = $event"
        disable-full-click
        :height="height"
        class="mt-4"
        image
        public
        :no-border="noBorder"
    >

      <v-sheet width="50" class="mx-auto" color="transparent">
          <ft-select :items="addImageActionsSelect" >
            <v-btn class="mx-auto" :style="buttonStyle"  :color="wsATTENTION" icon>
              <v-icon large   :color="wsATTENTION">mdi-plus-circle</v-icon>
            </v-btn>
          </ft-select>
        </v-sheet>

      <slot>
          <h5 :style="`color : ${wsACCENT}`" style="max-width: 180px" class="mt-2 mx-auto">
            {{ $t('uploader.choose_image') }}
          </h5>
        </slot>

    </ws-file-uploader>
  </v-sheet>
  <div v-if="!buttonOnly && image">
    <v-sheet
        :color="wsLIGHTCARD"
        class="wsRoundedLight"
    >
      <div class="image-with-chessboard"
      >
        <v-img
            :src="image"
            class="wsRoundedLight"
            aspect-ratio="1.5"
            contain
        />
      </div>


      <h5 class="my-2 px-4 shorten-text" :style="`color : ${wsACCENT}`"> {{ imageName }} </h5>
      <div class="d-flex justify-space-between  py-1">
        <v-btn @click="image = null" icon :color="wsACCENT" class="ml-1" >
          <v-icon  >mdi-delete-outline</v-icon>
        </v-btn>

        <ft-select :items="addImageActionsSelect" >
          <ws-button  label="Change" text :color="wsACCENT" :click-stop="false" />
        </ft-select>

      </div>

    </v-sheet>
<!--    @change="updateSettings(); "-->
    <a-parameter-slider
        v-if="settings"
        v-model="alignHorizontal"
        @change="updateAlignHorizontal"
        :track-color="wsDARKLIGHT"
        :value-color="wsACCENT"
        label="Horizontal"
        min="0"
        max="100"
        class="mt-4"
        light
    />
    <a-parameter-slider
        v-if="settings"
        v-model="alignVertical"
        @change="updateAlignVertical"
        :track-color="wsDARKLIGHT"
        :value-color="wsACCENT"
        label="Vertical"
        min="0"
        max="100"
        class="mt-4"
        light
    />

    <a-parameter-switch
        v-if="settings"
        v-model="containImage"
        @input="updateContainImage()"
        :label="$t('avalon.image.contain')"
        class="mt-6 "
        light
    />
    <v-divider
        v-if="settings"
        class="mt-4 pb-3"
        :style="`border-color : ${wsDARKLIGHT}`"
    />


  </div>
  <div v-if="buttonOnly">
    <ft-select v-if="!uploading" :items="addImageActionsSelect" >
      <ws-button
          label="AddImage"
          :block="buttonOnlyBlockStyle"
          :click-stop="false"
          :loading="uploading"
      />
    </ft-select>
    <v-progress-linear v-else :color="wsACCENT" :value="loadingProgress" height="40" class="wsRoundedLight" dark >
      <h5> {{ loadingProgress }} %</h5>
    </v-progress-linear>
  </div>


</div>
</template>

<script>
import imageLibraryDialog from "@/components/AvalonEditor/Dialogs/imageLibraryDialog";
export default {
  name: "imageParamPicker",
  components : {
    imageLibraryDialog
  },
  props : {
    value : {
      type : String,
      default : null,
    },
    label : {
      type : String,
    },
    tooltip : {
      type : String,
    },
    uploading : {
      type : Boolean,
      default : false
    },
    vertical : {
      type : Number,
      default : 50
    },
    horizontal : {
      type : Number,
      default : 50
    },
    contain : {
      type : Boolean
    },
    settings : {
      type : Boolean,
      default : false
    },
    height : {
      type : String,
    },
    buttonStyle : {
      type : String
    },
    noBorder : {
      type : Boolean,
      default : false
    },
    buttonOnly : {
      type : Boolean,
      default : false
    },
    buttonOnlyBlockStyle : {
      type : Boolean,
      default : false
    },
    disablePortal : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      image : null,
      alignVertical : 50,
      alignHorizontal : 50,
      containImage : false,
      displayLibrary : false,
      selectFunction : () => {},
      loadingProgress : 0,
    }
  },
  computed : {
    imageName() {
      if ( !this.image ) {
        return ''
      }
      return this.image.split('/')[this.image.split('/').length - 1]
    },
    addImageActionsSelect() {
      return [
        { text : this.$t('Upload') , value : 'upload',  icon : 'mdi-upload' , action :  this.selectFunction  },
        { text : this.$t('BrowseCollection') , value : 'browse' , icon : 'mdi-grid' , action : () => { this.displayLibrary = true }},
      ]
    }
  },
  watch : {
    vertical() {
      if ( this.vertical !== this.alignVertical ) {
        this.alignVertical = this.vertical
      }
    },
    horizontal() {
      if ( this.horizontal !== this.alignHorizontal ) {
        this.alignHorizontal = this.horizontal
      }
    },
    contain() {
      if ( this.contain !== this.containImage ) {
        this.containImage = this.contain
      }
    },

    image() {
      if ( this.image !== this.value ) {
        this.$emit('input', this.image)
        this.loadingProgress = 0
      }
    },
    value() {
      if ( this.image !== this.value ) {
        this.image = this.value
      }
    }
  },
  methods : {

    updateLoadingProgress(value) {
      this.loadingProgress = value
    },
    updateAlignVertical() {
      if ( this.vertical !== this.alignVertical ) {
        this.$emit('vertical' , this.alignVertical)
      }
    },
    updateAlignHorizontal() {
      if ( this.horizontal !== this.alignHorizontal ) {
        this.$emit('horizontal' , this.alignHorizontal)
      }
    },
    updateContainImage() {
      if ( this.contain !== this.containImage ) {
        this.$emit('contain' , this.containImage)
      }
    },

    uploadImage($event) {
      if ( !$event.url ) {
        return
      }
      this.image = $event.url
    },
  },
  beforeMount() {

    if ( this.horizontal ) {
      this.alignHorizontal = this.horizontal
    }
    if ( this.vertical ) {
      this.alignVertical = this.vertical
    }
    this.containImage = this.contain
  },
  mounted() {
    if ( this.value ) {
      this.image = this.value
    }
  }
}
</script>

<style scoped>
.image-with-chessboard {
  --cell-size: 20px;
  background:
      repeating-conic-gradient(var(--wsBACKGROUND) 0% 25%, transparent 0% 50%)
      50% / 20px 20px
}
</style>